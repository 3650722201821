import React from 'react';
import Slider from './Slider';
import '../styles/Cursos.css';
import { Helmet } from 'react-helmet';

const Cursos= () => {
    return (
        <div className="cursos-container">

<Helmet>
    <title>Making Friends - Cursos Gratuitos para la Vida Adulta</title>
    <meta name="description" content="Aprende a manejar tus finanzas, cuidar tu bienestar y encontrar tu propósito con los cursos gratuitos e interactivos de Making Friends. Obtén recompensas mientras te preparas para la vida adulta." />
</Helmet>

          
        <div id="parallax-lvl-0">
          <div id="b0-1" className="bubble size1 blanca">&nbsp;</div>
          <div id="b0-2" className="bubble size4 rosa">&nbsp;</div>
          <div id="b0-3" className="bubble size2 rosa">&nbsp;</div>
        </div>

        <div id="parallax-lvl-1">
          <div id="b1-1" className="bubble size1 blanca">&nbsp;</div>
          <div id="b1-3" className="bubble size2 blanca">&nbsp;</div>
        </div>

        <div id="parallax-lvl-2">
          <div id="b2-1" className="bubble size2 rosa">&nbsp;</div>
          <div id="b2-2" className="bubble size1 blanca">&nbsp;</div>
        </div>

        <div id="parallax-lvl-3">
          <div id="b3-1" className="bubble size2 blanca">&nbsp;</div>
          <div id="b3-2" className="bubble size4 rosa">&nbsp;</div>
        </div>


        <div className="cursos-content">
            <h1 className="cursos-text">Avanza a través de los <span className="special-text">Niveles de la Adultez</span></h1>
            <p className="home_p_principal">Completa cursos, avanza y desbloquea recompensas.</p>
            <img className="cursos-avatar" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Landing%2FScrenshotCursos.png?alt=media&token=544f5060-3e34-4064-b68e-657f0aaa91aa" alt="Avatar de IA-INU" />
            </div>



        <section className="home_texto_wrapper">
        <div className="home_texto_izquierda">
          <h2 className="home_texto_derecha_h2_1">Tenemos <span className="special-text">cursos</span> sobre</h2>
        </div>

        <div className="home_texto_derecha">
          <div>
            <h2 className="home_texto_derecha_h2">Cómo usar una tarjeta de crédito</h2>
          </div>

          <div>
            <h2 className="home_texto_derecha_h2">Documentos importantes que debes tener</h2>
          </div>

          <div>
            <h2 className="home_texto_derecha_h2">Lo necesario para terminar tu tesis</h2>
          </div>
          
        </div>
        </section>


<section className="cursos-recompensas">
        <div className="cursos-content">
            <h1 className="cursos-text">Podrás ganar increíbles <span className="special-text">recompensas</span></h1>
            <p className="home_p_principal">Cada que termines un curso desbloquearás:</p>
        </div>

        <div className="slider">
        <Slider />
        </div>

 </section>

 <section className="final-section">
          <h1 className="cursos_final_texto">Aprende a ser adulto,<span className="special-text"> gana recompensas y diviértete.</span></h1>
          <div className="contenedor_home_btn">
              <h1 className="home_h2">Descarga la app</h1>
              <a href="https://play.google.com/store/apps/details?id=com.makingfriends" className="home-app-link">
                <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fplay-logo.png?alt=media&token=98ad72aa-e73d-48f9-a13a-4c1aa784f1c0" alt="Descargar en Google Play" />
              </a>
              <a href="https://apps.apple.com/mx/app/making-friends/id6502792919" className="home-app-link">
                <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fapple-store.png?alt=media&token=a608c945-b9ea-450a-9529-97f20ebc6693" alt="Descargar en App Store" />
              </a>
            </div>
      </section>
       

        

</div>







    );
};

export default Cursos;
