import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import { IonIcon } from '@ionic/react';
import { logoFacebook, logoInstagram, logoTiktok, logoLinkedin } from 'ionicons/icons';

import Intercom from '@intercom/messenger-js-sdk';

Intercom({
  app_id: 'tyfgko4j',
});

function openIntercomChat() {
  if (window.Intercom) {
    window.Intercom('show'); // Abre el chat de Intercom
  } else {
    console.error("Intercom no está disponible");
  }
}


function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <div className="footer-logo">
            <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2FAvatarPerfil.png?alt=media&token=a8aada87-ae21-45b2-9422-56a1ff0ad8ee" alt="Logo Making Friends" />
          </div>
          <div className="footer-column">
            <h4>INFORMACIÓN</h4>
            <ul>
              <li><Link to="/privacidad">Privacidad</Link></li>
              <li><Link to="/terminos">Términos y condiciones</Link></li>
              <li><a href="https://makingfriends.typeform.com/to/ZGnLjkze">Friends</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>AYUDA</h4>
            <ul>
              <li>
                <button type="button" onClick={openIntercomChat} className="link-button">
                  Ponte en contacto
                </button>
              </li>
            </ul>
          </div>
          <div className="footer-apps">
            <a href="https://play.google.com/store/apps/details?id=com.makingfriends" className="footer-app-link">
              <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fplay-logo.png?alt=media&token=98ad72aa-e73d-48f9-a13a-4c1aa784f1c0" alt="Descargar en Google Play" />
            </a>
            <a href="https://apps.apple.com/mx/app/making-friends/id6502792919" className="footer-app-link">
              <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fapple-store.png?alt=media&token=a608c945-b9ea-450a-9529-97f20ebc6693" alt="Descargar en App Store" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-social-media">
        <a href="https://www.instagram.com/makingfriendsmx/"><IonIcon icon={logoInstagram} /></a>
        <a href="https://www.tiktok.com/@making.friendsmx"><IonIcon icon={logoTiktok} /></a>
        <a href="https://www.linkedin.com/company/making-friends-mx"><IonIcon icon={logoLinkedin} /></a>
        <a href="https://www.facebook.com/MakingFriends.MX"><IonIcon icon={logoFacebook} /></a>

      </div>
      <div className="footer-bottom-text">
        <p>QUE SER ADULTO TE SEA MÁS SENCILLO</p>
      </div>

      <div className="footer-bottom-Linadi">
        <p>Todos los derechos reservados Linadi, SA. de CV.</p>
      </div>
    </footer>
  );
}

export default Footer;
