import React from 'react';
import '../styles/Expertos.css';
import { Helmet } from 'react-helmet';

const Expertos = () => {
    return (
        <section className="Expertos">
<Helmet>
    <title>Making Friends - Directorio de Expertos en Vida Adulta</title>
    <meta name="description" content="Accede a servicios esenciales para la vida adulta de manera sencilla y confiable." />
</Helmet>

            <div className="Expertos_content">
                <h1 className="Expertos_h1">Forma parte de nuestra <span className="special-text">red de Expertos</span>y empieza a ganar clientes.</h1>
                <p className="Expertos_p">Ofrece tus servicios en nuestra plataforma y haz crecer tu negocio con acceso directo a una comunidad en busca de asesoramiento profesional.</p>

                <a href="https://makingfriends.typeform.com/to/CarOM6TS">
                            <button className="IA-INU-button">
                                Quiero ser un Experto
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="button-icon" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </button>
                        </a>
            </div>

            <section className="empresa-section">
                <div className="empresa-grid">
                    <div className="empresa-item">
                        <div className="empresa-circle--legal"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Legal</h3>
                            <p className="empresa-description">Asesoría legal para dudas o incidentes que necesites.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--dental"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Salud Dental</h3>
                            <p className="empresa-description">Cuidado dental experto para sonrisas saludables.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--nutricional"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Nutricional</h3>
                            <p className="empresa-description">Mejora tu salud con asesoría nutricional personalizada.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--emprendimiento"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Emprendimiento</h3>
                            <p className="empresa-description">Aumenta la visibilidad de tu marca entre usuarios comprometidos y motivados.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--contable"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Contable</h3>
                            <p className="empresa-description">Asesoría contable para una gestión eficiente de tus finanzas.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--laboral"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Laboral</h3>
                            <p className="empresa-description">Asesoría laboral para solucionar cualquier duda o conflicto.</p>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Expertos;
